import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/Layout";
import SEO from "../components/common/SEO";
import Content from "../components/common/Content";

class PrivacyPage extends React.Component {
  componentDidMount() {
    (function (w, d) {
      var loader = function () {
        var s = d.createElement("script"),
          tag = d.getElementsByTagName("script")[0];
        s.src = "https://cdn.iubenda.com/iubenda.js";
        tag.parentNode.insertBefore(s, tag);
      };
      loader();
    })(window, document);
  }

  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Privacy Policy" />
        <Content>
          <h2>Privacy Policy</h2>
          <a
            href="https://www.iubenda.com/privacy-policy/48379781"
            className="iubenda-white iubenda-embed"
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
        </Content>
      </Layout>
    );
  }
}

export default PrivacyPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
